.container {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 25em;
  height: 10em;
  max-height: 15em;

  background: var(--modal-background);
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 10px;
  padding: 1em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0302294C;
  z-index: 1;
}

.title {
  font-size: 1.2em;
}

.button-container {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  gap: .5em;
}