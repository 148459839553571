.container {
  /* height: 100%; */
  height: calc(100% - 2.5em);
  background-color: var(--modal-background);
  border-radius: 10px;
  padding: 1em 1.5em 1.5em 1.5em;
}

.title {
  font-size: 1.2em;
  font-weight: 600;
}

.content {
  display: flex;
  height: calc(100% - 1.2em);
  flex-direction: column;
  justify-content: space-between;
}

.no-title {
  height: 100%;
}
