.chart-container {
  display: grid;
  --n: 4;
  grid-template-columns: 1fr .75fr .75fr .75fr;
  gap: .75em;
  margin-bottom: .75em;
}

.indicators {
  display: grid;
  --n: 5;
  grid-template-columns: repeat(auto-fill, minmax(max(19em, calc(100%/var(--n)) - .60em), 1fr));
  gap: .75em;
  margin-bottom: .75em;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: .75em;
  margin-bottom: .75em;
}

.dividends {
  max-width: 32em;
}

.buttons {
  display: flex;
  gap: .75em;
  margin-top: .75em;
}

.button {
  font-weight: 400;
  margin-left: .75em;
  font-size: .65em;
  max-width: 15em;
}

.button-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.table-buttons {
  display: flex;
  gap: 0.4em;
}

.emoji {
  font-size: .8em;
}