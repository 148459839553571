.container {
  flex-direction: column;
  display: flex;
  align-items: stretch;
  margin: 1em 0;
}

.filter-container {
  margin: .5em 0;
}

.container-hidden {
  display: none;
}

.input {
  background: var(--control-background);
  height: 3em;
  border: none;
  padding: 0 .75em;
  border-radius: 10px;
  font-family: inherit;
  font-size: 1em;
}

.label {
  margin-top: .5em;
}

.white {
  background: var(--modal-background);
}

.input:disabled {
  background: var(--control-disabled);
}

.input:focus {
  background-color: var(--active-element);
  border: none;
  outline: none;
}

.input:focus::-webkit-input-placeholder {color: transparent}
.input:focus::-moz-placeholder          {color: transparent}
.input:focus:-moz-placeholder           {color: transparent}
.input:focus:-ms-input-placeholder      {color: transparent}