@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: var(--text-color);
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --success: #2ad22a
}

:root[data-theme="light"] {
  --background: #fafafb;
  --modal-background: #ffffff;
  --text-color: #000;
  --disabled-text-color: #7a7a7a;
  --control-background: #F7F7F8;
  --table-headers: #030229B3;
  color-scheme: light;
  --navbar-filter: none;
  --emoji-button-bg: #f0f0f5;
  --emoji-button-bg-disabled: #f5f5f8;
  --emoji-button-bg-hover: #eaeaef;
  --skeleton-base-color:#f5f5f8;
  --skeleton-highlight-color: #f9f9f9;
  --active-element: #f5f5f5;
  --primary: #605bff;
  --control-disabled: #FBFBFC;
  --info: #d5edfc;
  --bar-chart-secondary: #ececec;
}

:root[data-theme="dark"] {
  --background: #222226;
  --modal-background: #1a1919;
  --text-color: #fff;
  --disabled-text-color: #8d8d8d;
  --control-background: #202023;
  --table-headers: rgba(214, 234, 253, 0.65);
  color-scheme: dark;
  --navbar-filter: brightness(0) saturate(100%) invert(97%) sepia(3%) saturate(1424%) hue-rotate(199deg) brightness(91%) contrast(89%);
  --emoji-button-bg: #25252f;
  --emoji-button-bg-disabled: #232327;
  --emoji-button-bg-hover: #31313f;
  --skeleton-base-color: #25252f;
  --skeleton-highlight-color: #21212b;
  --active-element: #1c1b1e;
  --primary: #605bff;
  --control-disabled: #1b1b1e;
  --info: #4785af;
  --bar-chart-secondary: #222226;
}
