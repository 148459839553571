.container {
  background: var(--modal-background);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 30em;
  border: none;
  box-shadow: none;
  outline: none;
  overflow-y: scroll;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0302294C;
}