.container {
  height: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table {
  width: 100%;
  text-align: center;
  border-spacing: 0 .75em;
  table-layout: fixed;
}

.header {
  color: #030229B3;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.header-sort-direction {
  font-size: 0.7em;
  margin-left: .5em;
}

.row {
  background: var(--modal-background);
  height: 3em;
  border-radius: 10px;
}

.row:hover {
  background: var(--active-element);
}

.row td:first-child {
  border-radius: 10px 0 0 10px;
}

.row td:last-child {
  border-radius: 0 10px 10px 0;
}

.cell {
  pointer-events: none;
}