.container {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  margin: 1em 0 1em .25em;
  min-height: 60px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 700;
}

.filters {
  display: flex;
  flex-direction: row;
}

.filter {
  padding: .5em;
  display: flex;
  align-items: center;
}