.container {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  gap: .75em;
  margin-bottom: .75em;
}

.top-container {
  display: grid;
  height: 100%;
  grid-template-columns: 0.2fr 1fr;
  gap: .75em;
  margin-bottom: .75em;
}

.indicators {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form {
  height: 24em;
  width: 30em;
}

.button-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.button {
  font-weight: 400;
  margin-left: .75em;
  font-size: .65em;
  max-width: 15em;
}
