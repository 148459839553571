.button-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.button {
  font-weight: 400;
  margin-left: .5em;
  font-size: .65em;
  width: 10em;
}

.date-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-button {
  font-weight: 400;
  margin-left: .5em;
  font-size: .65em;
}
