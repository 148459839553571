.button {
  font: inherit;
  background: #605BFF;
  color: #FFFFFF;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: .7em;
}

.button:hover {
  cursor: pointer;
  background: #605BFFF5;
}

.button:disabled {
  background: #807cfc;
  cursor: default;
}

.secondary {
  color: var(--text-color);
  background: var(--modal-background);
  border: 2px solid #605BFF1B;
}

.secondary:hover {
  cursor: pointer;
  background: #F9F9FF;
}

.borderless {
  color: var(--text-color);
  background: var(--modal-background);
  border: none;
}

.borderless:hover {
  cursor: pointer;
  background: var(--background);
}
