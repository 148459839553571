.headers {
  border-bottom: 1px solid #03022930; 
  padding-bottom: .75em;
  justify-content: flex-start;
}

.container {
  min-height: 25em;
}

.table-container { 
  overflow: auto;
  padding-bottom: 1em;
}

.thead {
  position: sticky;
  top: .75em;
  background: var(--modal-background);
  box-shadow: 0 -.75em var(--modal-background);
}

.standard {
  height: 25.25em;
}
