.container {
  position: relative;
  display: flex;
  flex-direction: row;
  background: var(--background);
  justify-content: space-between;
  height: 100vh;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--modal-background);
  max-width: 23%;
  min-width: 25em;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  height: 60%;
}

.logo {
  width: 26%;
  /* height: 23%; */
  align-self: center;
}

.picture {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: x-large;
  font-weight: 600;
  align-self: center;
  margin: 2em 0;
}

.reset {
  font-size: .9em;
  text-decoration: none;
  color: #615bff57;
  align-self: flex-end;
}
