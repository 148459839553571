.container {
  display: grid;
  height: 100%;
  --n: 2;
  grid-template-columns: 0.75fr 1.25fr;
  gap: .75em;
  margin-bottom: .75em;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: .25em;
}
