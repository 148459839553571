.cell-rectangle {
    width: 6em;
    padding: .25em 0;
    border-radius: 8px;
    text-align: center;
}

.cell-rectangle-blue {
    background: #26C0E230;
    color: #26C0E2;
}

.cell-rectangle-red {
    background: #e22c2630;
    color: #e22c26;
}

.cell-rectangle-green {
    background: #26e24f30;
    color: #26e24f;
}

.cell-image {
    max-height: 2em;
    max-width: 2em;
    border-radius: 5px;
}

.no-image {
    opacity: 0.5;
}

.cell-start {
    justify-content: start !important;
    text-align: start;
}

.cell-end {
    justify-content: end !important;
    width: 75%
}

.cell {
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

