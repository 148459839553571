.container {
  flex-direction: column;
  display: flex;
  align-items: stretch;
  margin: 1em 0;
}

.label {
  margin-top: .5em;
}
