.indicator-container {
  display: grid;
  --n: 4;
  grid-template-columns: repeat(auto-fill, minmax(max(17em, calc(100%/var(--n)) - .75em), 1fr));
  gap: .75em;
  margin-bottom: .75em;
}

.chart-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-container {
  margin-bottom: .75em;
}

.chart-filter {
  font-size: 0.85em;
}

.table-container {
  display: flex;
  gap: .5em;
  align-items: center;
}

.trigger-button {
  font-size: 0.75em;
}

.containerOuter {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  gap: .75em;
  margin-bottom: .75em;
}

.localTable {
  display: grid;
  grid-template-columns: 1fr;
  gap: .75em;
  margin-bottom: .75em;
  white-space: nowrap;
}

.localTable td:first-child div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.chartBox {
  padding: .75em;
  height: 39.825em;
  max-height: 29.825em;
  border-radius: .75em;
  background: var(--modal-background);
}

.container {
  padding-top: 1em;
  height: 37.5em;
  max-height: 37.5em;
  overflow-y: scroll;
}

.container_bak {
  padding-top: 1em;
  height: 28.75em;
  max-height: 25.5em;
  overflow-y: scroll;
}

.chartCard {
  height: inherit;
  display: flex;
  padding-bottom: 1.35em;
}

.more-outer-boxes {
  position: relative;
}

.chart-shadow {
  position: absolute;
  box-shadow: 0 6.3em 0 0 var(--modal-background) inset;
  height: 6.3em;
  width: 90%;
  z-index: 1;
  border-radius: .75em;
}
