.container {
  display:flex;
  align-items: center;
}

.button {
  font-family: 'Courier New', Courier, monospace;
  margin-left: .5em;
  font-size: 1em;
  font-weight: 100;
  width: 2em;
  height: 1.5em;
  padding: 0;
}