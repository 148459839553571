.ok {
  background: #26e24f30;
  color: #26e24f;
}

.bad {
  background: #e2742630;
  color: #e27126
}

.danger {
  background: #e22c2630;
  color: #e22c26;
}

.fixed-column {
  width: 7.45em;
}


.button-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: .25em
}

.trigger-button {
  font-weight: 400;
  margin-left: .5em;
  font-size: .65em;
}

.turnover-type-switch {
  font-size: 0.7em;
}