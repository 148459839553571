.indicator-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(19em, calc(100%/var(--n)) - .60em), 1fr));
  gap: .75em;
  margin-bottom: .75em;
}

.indicator-5 {
  --n: 5;
}

.indicator-4 {
  --n: 4;
}

.chart-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: .75em;
  margin-bottom: .75em;
}

.table-and-pies-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: .75em;
  margin-bottom: .75em;
}

.order-amount {
  filter: invert(48%) sepia(79%) saturate(2083%) hue-rotate(160deg) brightness(93%) contrast(101%);
}

.order-money {
  filter: invert(52%) sepia(98%) saturate(728%) hue-rotate(11deg) brightness(111%) contrast(91%);
}

.sell-amount {
  filter: invert(86%) sepia(97%) saturate(210%) hue-rotate(77deg) brightness(99%) contrast(91%);
}

.sell-money {
  filter: invert(22%) sepia(58%) saturate(954%) hue-rotate(200deg) brightness(94%) contrast(94%);
}

.for-pay {
  filter: invert(44%) sepia(76%) saturate(580%) hue-rotate(75deg) brightness(97%) contrast(92%);
}

.danger {
  filter: invert(64%) sepia(7%) saturate(3554%) hue-rotate(314deg) brightness(89%) contrast(101%);
}

.chart-filter {
  display: flex;
  align-items: center;
}

.chart-switch {
  margin: 0 .5em !important;
  font-size: .8em;
}

.big-column {
  min-width: 14em;
}

.number-column {
  width: 9em;
}

.table {
  height: 35em;
}
