.container {
  margin: .1em 0 .1em 0;
  padding: 0.75em 0 .75em 1.75em;
  display: flex;
  text-decoration: none;
  /*color: #030229;*/
  color: var(--text-color);
  opacity: 0.5;
  font-weight: 600;
  font-size: 1.05em;
  filter: var(--navbar-filter);
}

.container:hover {
  background: linear-gradient(90deg, #03022929, rgba(172, 169, 255, 0) 18%);
}

.container-selected {
  margin: .1em 0 .1em 0;
  padding: 0.75em 0 .75em 1.75em;
  display: flex;
  filter: invert(32%) sepia(80%) saturate(1441%) hue-rotate(224deg) brightness(99%) contrast(105%);
  text-decoration: none;
  color: #030229;
  font-weight: 600;
  font-size: 1.05em;
  background: linear-gradient(90deg, #03022929, rgba(172, 169, 255, 0) 18%);
}

.icon {
  opacity: 0.8;
  width: 1.5em;
  margin-right: .75em;
}

.icon-selected {
  width: 1.5em;
  margin-right: .75em;
}