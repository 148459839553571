.button {
  background: var(--emoji-button-bg);
  /*background: var(--emoji-button-bg-disabled);*/
  border-radius: 100em;
  height: 2.65em;
  width: 2.65em;
}

.button:hover {
  background: var(--emoji-button-bg-hover);
}

.button:disabled {
  opacity: 50%;
  background: var(--emoji-button-bg-disabled);;
}