.container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.card {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 10em;
    gap: 2em;
    /*margin-top: 30vh;*/
}

.title {
    font-size: 8em;
}