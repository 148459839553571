.container {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.table {
  width: 100%;
  text-align: center;
  border-spacing: 0 .75em;
  table-layout: fixed;
}

.table-start {
  text-align: start;
}

.header {
  color: var(--table-headers);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.header-sort-direction {
  display: flex;
  font-size: 0.7em;
  margin-left: .5em;
  align-items: center;
}

.header-can-sort {
  cursor: pointer;
}

.header-start {
  justify-content: flex-start;
}

.icon {
  opacity: 0.5;
  width: 1.5em;
  filter: var(--navbar-filter);
}

.column-image {
  width: 3em;
}

.load-more-container {
  padding: 0 0 .75em;
}

.body {

}

.skeleton-container {
  padding: 0 .75em;
}

.clear-row {
  background: none !important;
  border-radius: 0;
}

.clear-row:hover {
  background: none;
}

.actions {
  display: flex;
  gap: .5em;
  width: 5em;
}

.column-actions {
  width: 5em;
}

.action-button {
  font-size: .75em;
  background: #fafaf7;
  border-radius: 100em;
  height: 2.65em;
  width: 2.65em;
}

.action-button:hover {
  background: #f5f5f3;
}

.row {

}

.skeleton-container {
  padding: 0 .75em;
}

.clear-row {
  background: none !important;
  border-radius: 0;
}

.clear-row:hover {
  background: none;
}
