.container {
    background: var(--modal-background);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 46em;
    height: 50em;
    border: none;
    box-shadow: none;
    outline: none;
    overflow-y: scroll;
    border-radius: 10px;
    color: var(--text-color);
}

.big-container {
    width: 80em;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0302294C;
    z-index: 1;
}

.table {
    position: static;
    display: grid;
    padding: 1em;
    gap: .5em;
}

.column-2 {
    grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr);
}

.column-4 {
    grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr);
}

.cell {
    display: flex;
    gap: 1em;
    align-items: center;
}

.input {
    width: 7em;
}

.fixed-button-container {
    display: flex;
    position: sticky;
    bottom: 1em;
    margin-right: 1em;
    justify-content: flex-end;
}

.fixed-button {
}

.switch {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2em;
}

.search-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;
}

.search {
    width: 17.45em;
}