.main-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background: var(--background);
}

.theme-switcher {
  display: flex;
  justify-content: center;
  height: 4em;
}

.navbar-container {
  width: 13%;
  min-width: 17em;
  display: flex;
  background: var(--modal-background);
  flex-direction: column;
  justify-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  width: 50%;
  margin-right: 2em;
  padding: 2.9em 0 2.4em 0;
}

.logo-image {
  width: 50%;
}

.logo-title {
  font-size: 1.5em;
  font-weight: 700;
}

.routes-container {
  flex-grow: 1;
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75em .75em 0.75em;
}

.profile-container {
  display: flex;
  text-decoration: none;
}

.user-avatar {
  width: 3em;
  border-radius: 12px;
}

.user-title {
  color: var(--text-color);
  font-size: small;
  font-weight: 800;
}

.user-sub-title {
  font-size: small;
  color: var(--text-color);
  opacity: 50%;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: .75em;
  flex-grow: 1;
}

.logout-icon {
  opacity: 40%;
  filter: var(--navbar-filter);
}


