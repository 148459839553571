.container {
  margin: 1em 0;
}

.filter {
  margin: 0;
}

.buttons-container {

  display: flex;
}

.label {
  margin-bottom: .25em;
}

.button {
  background: var(--control-background);
}

.filter-button { 
  background: var(--modal-background);
}

.button input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.button label {
  display: inline-block;
  min-width: 6em;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: .6em;
  transition: all 0.1s ease-in-out;
}

.button label:hover {
	cursor: pointer;
}

.button input:checked + label {
  background-color: #605BFF;
	color: #fff;
  box-shadow: none;
  border-radius: 10px;
}

.buttons-container div:first-of-type {
  border-radius: 10px 0 0 10px;
}

.buttons-container div:last-of-type {
  border-radius: 0 10px 10px 0;
}

.button-disabled {
  background: var(--control-disabled);
}

.button-disabled label:hover {
	cursor: default;
}

.button-disabled input:checked + label {
  background-color: #807cfc;
}
